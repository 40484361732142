// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

// ! a mettre en TSX !!!!!!!!!!!!!!!!!!!!
//import env from "react-dotenv"
/*
export function getAccessToken() {
	return localStorage.getItem("accessToken") // ?????????????????????????????????????????
}

*/
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

//export const API_BASE_URL = "http://localhost:3002"

export const API_BASE_URL = "https://appdesigner.api.zedixi.com"
//export const API_BASE_URL = env.BACK_BASE_URL
